<template>
  <div class="flex">
    <div class="m-2">
      <b-field label="">
        <b-input
          v-model="inputSN"
          autofocus
          placeholder="serial number"
          @keyup.native.enter="enter"
          ref="input"
          size="is-medium"
          v-on:blur="setFocus()"
          :loading="loading"
          :disabled="loading"
        ></b-input>
      </b-field>

      <div v-if="inventory">
        <div class="title is-5">
          {{ inventory.id }} : {{ inventory.product_name }} - {{ inventory.hardware_revision }} -
          {{ inventory.assembly_variant }} <br />
        </div>

        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="organization">
          <ul>
            <li>
              <a :href="`https://hardwario.cloud/#/management/organization/${organization.id}`" target="_blank">
                {{ organization.name }}
              </a>
            </li>
            <li>
              <a :href="`https://hardwario.cloud/#/management/group/${group.id}`" target="_blank">
                {{ group.name }}
              </a>
            </li>

            <li>
              <a :href="`https://hardwario.cloud/#/management/device/${device.id}`" target="_blank">
                {{ device.label }}{{ device.name ? ` - ${device.name}` : '' }}
              </a>
            </li>
          </ul>
        </nav>

        <div v-if="!loading">
          <div v-if="message">
            Received: {{ message.created_at }}
            <vue-json-pretty :data="message.data"></vue-json-pretty>
          </div>
          <div v-else>No message found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'deviceInfo',
  data() {
    return {
      loading: false,
      inputSN: '', // 2147484190
      inventory: null,
      device: null,
      message: null,
      group: null,
      organization: null,
    };
  },
  created() {},
  mounted() {
    this.setFocus();
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
    },
    enter() {
      if (!this.inputSN || this.inputSN.length < 3) return;

      this.loading = true;
      this.inventory = null;
      this.device = null;
      this.message = null;
      this.group = null;
      this.organization = null;

      this.$http
        .get(`api/v1/inventory/HSN-${this.inputSN}`)
        .then(({ data }) => {
          this.inventory = data;
          const label = this.inventory.id.replace('HSN-', 'hsn:');
          return this.$http.get(`v1/search?text=${label}`);
        })
        .then(({ data }) => {
          console.log(data);
          if (data.length !== 1) {
            const label = this.inventory.imsi;
            return this.$http.get(`v1/search?text=${label}`);
          }
          return { data };
        })
        .then(({ data }) => {
          if (data.length !== 1) {
            this.$toast.error('Cannot found in hardwario.cloud');
          }
          return this.$http.get(`v1/device/${data[0].id}`);
        })
        .then(({ data }) => {
          this.device = data;
          return this.$http.get('v1/messages', { params: { device_id: this.device.id, limit: 1 } });
        })
        .then(({ data }) => {
          if (data.length === 1) this.message = data[0];
          return this.$http.get(`v1/group/${this.device.group_id}`);
        })
        .then(({ data }) => {
          this.group = data;
          return this.$http.get(`v1/organization/${this.group.organization_id}`);
        })
        .then(({ data }) => {
          this.organization = data;
        })
        .finally(() => {
          this.inputSN = null;
          this.loading = false;
          this.setFocus();
        });
    },
  },
};
</script>
